<template>
    <el-drawer v-model="isDrawerShow" :show-close="false" size="952px" @close="$emit('close')"
        :close-on-click-modal="true" ref="drawerRef" custom-class="budget-operate-drawer">
        <template #title>
            <div class="top-container">
                <span>预算</span>
                <el-icon @click="$emit('close')">
                    <i class="iconfont icon-ylclose"></i>
                </el-icon>
            </div>
        </template>
        <template #default>
            <el-form ref="formRef" :model="form">
                <vxe-table :data="form.tableData" show-overflow :row-config="{ height: 78 }" min-height="108" round>
                    <vxe-column field="name" :title="`${type === 0 ? '广告系列' : type === 1 ? '广告组' : '广告'}名称`"
                        min-width="150px"></vxe-column>
                    <vxe-column field="spend" title="消耗">
                        <template #default="{ row }">
                            {{ row.spend !== 0 ? `$${base.thousands(row.spend)}` : row.spend }}
                        </template>
                    </vxe-column>
                    <vxe-column field="daily_budget" title="预算" min-width="150px">
                        <template #default="slotParams">
                            <span v-if="!slotParams.row.daily_type" style="color: #8c8c8c;line-height: 78px;">{{
                                slotParams.row.daily_budget === "使用广告组预算" ?
                                    '此预算必须在广告组层级编辑' :
                                    '此预算必须在广告系列层级编辑' }}</span>
                            <el-form-item :prop="`tableData[${slotParams.rowIndex}].daily_budget`" :rules="[
                                { required: true, message: '请输入预算' },
                                { pattern: /^[1-9]\d*$/, message: '预算金额必须为大于0的整数' },
                            ]" v-else>
                                <el-input v-model="slotParams.row.daily_budget" placeholder="请输入预算" size="medium">
                                    <template #prepend>
                                        <el-select v-model="budgetType" placeholder="Select" style="width: 115px"
                                            size="medium" disabled>
                                            <el-option label="日预算" value="1" />
                                        </el-select>
                                    </template>
                                    <template #prefix>
                                        <span>$</span>
                                    </template>
                                    <template #suffix>
                                        <span>USD</span>
                                    </template>
                                </el-input>
                            </el-form-item>

                        </template>
                    </vxe-column>
                </vxe-table>
            </el-form>
            <div class="footer">
                <el-button @click="$emit('close')" size="small">取消</el-button>
                <el-button type="primary" size="small" @click="confirmClick">确定</el-button>
            </div>
        </template>
    </el-drawer>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import base from '@/base/filters/base'
import api from '@/request/api/ad'
import { ElLoading } from 'element-plus'

const { proxy } = getCurrentInstance()
const props = defineProps({
    data: { type: Array, default: () => [] },
    type: { type: Number, default: 0 }
})

const formRef = ref()
const form = ref({
    tableData: JSON.parse(JSON.stringify(props.data))
})
const emit = defineEmits(['close', 'submit'])
const isDrawerShow = ref(true)
const budgetType = ref('1')

const confirmClick = () => {
    let params = form.value.tableData.filter(item => item.daily_type !== '')
    if (props.type === 2 || params.length === 0) return
    formRef.value.validate((valid) => {
        if (valid) {
            const loading = ElLoading.service({
                spinner: 'el-icon-loading',
                lock: true,
                text: '正在提交修改中...'
            })
            params = params.map(item => {
                return {
                    id: item.id,
                    budget: item.daily_budget
                }
            })
            const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
            api.series.updateBudget(JSON.stringify(params), user.fb_user_id, props.type + 1).then(res => {
                if (res.code === 200) {
                    proxy.$alert(`以下是预算修改情况:<br>${res.msg}`, '信息', {
                        confirmButtonText: '收到',
                        dangerouslyUseHTMLString: true,
                        callback: (action) => {
                            emit('submit')
                        }
                    })
                }
            }).finally(() => {
                loading.close()
            })
        } else {
            return false
        }
    })
}
</script>


<style>
.budget-operate-drawer {
    .el-drawer__header {
        height: 64px;
        padding: 0;
        margin: 0;
        display: block;
    }

    .el-drawer__body {
        overflow-y: auto;
        padding: 24px 16px 64px 16px;
        margin: 0;

        .vxe-table {
            .vxe-cell {
                color: #262626;
                padding: 0 16px;
            }

            .vxe-header--row {
                color: #262626;
                background-color: #ffffff;
                font-family: 'PingFangSC-Medium, PingFang SC';
            }

            .vxe-header--column {
                padding: 9px 0;
            }

            .vxe-body--column:nth-child(3) {
                .vxe-cell {
                    height: 100%;
                    position: relative;

                    .el-form-item {
                        width: 320px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        margin-bottom: 18px;

                        .el-form-item__error {
                            left: 115px;
                        }
                    }
                }
            }

            .el-input-group>.el-input__inner {
                color: #262626
            }

            .el-input-group__prepend .el-input__suffix-inner {
                height: 36px !important;
                margin-top: 0 !important;
            }

            .el-input__suffix {
                right: 10px;

                .el-input__suffix-inner {
                    color: #262626;
                    margin-top: 7px;
                    display: inline-block;
                }
            }

            .el-input__prefix {
                left: 20px;

                span {
                    color: #262626;
                    height: 100%;
                    line-height: 34px;
                    display: inline-block;
                }
            }
        }
    }
}
</style>
<style lang="less" scoped>
.top-container {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid #F0F0F0;

    span {
        font-size: 16px;
        font-family: PingFang SC-Medium;
        font-weight: 600;
        color: #262626;
        line-height: 24px;
    }

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 952px;
    height: 64px;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    padding: 16px 24px;
    justify-content: flex-end;
    background-color: #ffffff;
    z-index: 999;

    :deep(.el-button) {
        margin-left: 20px;
        font-family: PingFang SC-Medium;
        font-size: 14px;
    }
}
</style>